import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, List, ListItem, ListItemText, Divider, Box, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config';

function ReviewPage() {
  const [reviewedSamples, setReviewedSamples] = useState([]);
  const [sampleCounts, setSampleCounts] = useState({ reviewed: 0, total: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    fetchReviewedSamples();
    fetchSampleCounts();
  }, []);

  const fetchReviewedSamples = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/reviewed-samples`);
      setReviewedSamples(response.data);
    } catch (error) {
      console.error('Error fetching reviewed samples:', error);
    }
  };

  const fetchSampleCounts = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/sample-counts`);
      setSampleCounts({
        reviewed: response.data.reviewed_samples,
        total: response.data.total_samples
      });
    } catch (error) {
      console.error('Error fetching sample counts:', error);
    }
  };

  const handleSampleClick = (clipId) => {
    navigate(`/?clipId=${clipId}`);
  };

  return (
    <Container maxWidth="md" sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 2 }}>
        Reviewed Samples
      </Typography>
      <Typography variant="h6" gutterBottom>
        Progress: {sampleCounts.reviewed}/{sampleCounts.total} samples reviewed
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        (Showing samples that don't need human review and have passed quality check)
      </Typography>

      <Button variant="contained" onClick={() => navigate('/')} sx={{ mb: 2 }}>
        Back to Main Page
      </Button>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <List sx={{ 
          height: 'calc(100vh - 250px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
          }
        }}>
          {reviewedSamples.map((sample) => (
            <React.Fragment key={sample.id}>
              <ListItem 
                button 
                onClick={() => handleSampleClick(sample.id)}
                secondaryAction={
                  sample.dont_include && (
                    <Chip label="Don't Include" color="error" size="small" />
                  )
                }
              >
                <ListItemText
                  primary={sample.final_transcription || "No transcription (Don't Include)"}
                  secondary={new Date(sample.verified_at).toLocaleString()}
                  primaryTypographyProps={{ 
                    style: { 
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    } 
                  }}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Container>
  );
}

export default ReviewPage;
