import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Button, TextField, Stack, Box, Alert, Link, Snackbar } from '@mui/material';
import axios from 'axios';
import styles from '../styles/styles';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { API_URL } from '../config';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledAudioPlayer = styled('audio')({
  width: '100%',
  marginBottom: 16,
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  marginBottom: theme.spacing(6),
}));

const formatYouTubeLink = (link, timestamp) => {
  if (!link || !timestamp) return link;
  
  const match = timestamp.match(/Start: (\d+):(\d+):(\d+)/);
  if (match) {
    const [, hours, minutes, seconds] = match;
    const totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    
    const url = new URL(link);
    url.searchParams.set('t', totalSeconds);
    return url.toString();
  }
  
  return link;
};

const formatTimestamp = (timestamp) => {
  const match = timestamp.match(/Start: (\d+):(\d+):(\d+)\.(\d+), End: (\d+):(\d+):(\d+)\.(\d+)/);
  if (match) {
    const [, startHour, startMin, startSec, , endHour, endMin, endSec] = match;
    return `${startMin}:${startSec} - ${endMin}:${endSec}`;
  }
  return timestamp; // Return original if it doesn't match expected format
};

function TranscriptionCorrector() {
  const [audioSrc, setAudioSrc] = useState('');
  const [whisperTranscription, setWhisperTranscription] = useState('');
  const [aiSuggestion, setAiSuggestion] = useState('');
  const [finalTranscription, setFinalTranscription] = useState('');
  const [currentClipId, setCurrentClipId] = useState(null);
  const [currentFilename, setCurrentFilename] = useState('');
  const [medTerms, setMedTerms] = useState('');
  const [qualityCheck, setQualityCheck] = useState('');
  const [needsHumanReview, setNeedsHumanReview] = useState(false);
  const [noClipsMessage, setNoClipsMessage] = useState('');
  const [activeClipId, setActiveClipId] = useState(null);
  const [isEditable, setIsEditable] = useState(true);  // Always set to true initially
  const [humanVerified, setHumanVerified] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [ytLink, setYtLink] = useState('');
  const [timestamps, setTimestamps] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const clipId = params.get('clipId');
    if (clipId) {
      loadClip(clipId);
    } else {
      loadNextClipToReview();
    }
  }, [location]);

  const loadNextClipToReview = async () => {
    console.log("loadNextClipToReview called");
    try {
      console.log("Sending request to:", `${API_URL}/api/next-clip-to-review`);
      const response = await axios.get(`${API_URL}/api/next-clip-to-review`);
      console.log("Response received:", response.data);
      if (response.data.id) {
        await loadClip(response.data.id);
        setActiveClipId(response.data.id);
        setNoClipsMessage('');
      } else {
        console.log(response.data.message);
        // Reset state and show message
        setAudioSrc('');
        setWhisperTranscription('');
        setAiSuggestion('');
        setFinalTranscription('');
        setCurrentClipId(null);
        setCurrentFilename('');
        setMedTerms('');
        setHumanVerified(false);
        setNoClipsMessage('No more clips to review. All transcriptions are complete!');
      }
    } catch (error) {
      console.error('Error loading next clip to review:', error);
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
      }
      setNoClipsMessage('Error loading next clip. Please try again later.');
    }
  };

  const loadClip = async (clipId) => {
    try {
      const response = await axios.get(`${API_URL}/api/load-clip/${clipId}`);
      console.log("Load clip response:", response.data);
      if (response.data) {
        const audioUrl = `${API_URL}${response.data.audioUrl}`;
        console.log("Setting audio URL to:", audioUrl);
        setAudioSrc(audioUrl);
        setWhisperTranscription(response.data.whisperTranscription);
        setAiSuggestion(response.data.aiSuggestion);
        setFinalTranscription(response.data.finalTranscription || '');
        setCurrentClipId(response.data.id);
        setActiveClipId(response.data.id);
        setCurrentFilename(response.data.audioUrl.split('/').pop());
        setMedTerms(response.data.medTerms);
        setQualityCheck(response.data.qualityCheck);
        setNeedsHumanReview(response.data.needsHumanReview);
        setHumanVerified(response.data.humanVerified);
        setIsEditable(true);  // Always allow editing
        setPlaybackSpeed(1);
        const audioElement = document.querySelector('audio');
        if (audioElement) {
          audioElement.playbackRate = 1;
        }
        setYtLink(response.data.ytLink);
        setTimestamps(response.data.timestamps);
        setNoClipsMessage('');
      }
    } catch (error) {
      console.error('Error loading clip:', error);
      setNoClipsMessage('Error loading clip. Please try again later.');
    }
  };

  const handleSave = async () => {
    try {
      console.log("Saving transcription for clip:", activeClipId);
      const payload = { final_transcription: finalTranscription };
      console.log("Payload being sent:", JSON.stringify(payload));
      const response = await axios.post(`${API_URL}/api/save-transcription/${activeClipId}`, payload);
      console.log("Save response:", response.data);
      setHumanVerified(true);
      // Show success message using Snackbar
      setSnackbarMessage("Transcription saved successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving transcription:', error);
      // Show error message using Snackbar
      setSnackbarMessage("Error saving transcription. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCopyToFinal = (text) => {
    setFinalTranscription(text);
  };

  const handleLoadAdjacentClip = async (direction) => {
    try {
      console.log(`Loading ${direction} clip for clip ID:`, currentClipId);
      const response = await axios.get(`${API_URL}/api/get-adjacent-clip/${currentClipId}/${direction}`);
      console.log("Adjacent clip response:", response.data);
      if (response.data.id) {
        await loadClip(response.data.id);
      } else {
        console.log('No more clips in this direction');
      }
    } catch (error) {
      console.error(`Error loading ${direction} clip:`, error);
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error details:', error.response.data);
      }
    }
  };

  const handleReset = () => {
    loadClip(activeClipId);  // Load the active clip instead of currentClipId
  };

  const handleDontInclude = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/dont-include/${activeClipId}`);
      console.log("Don't Include response:", response.data);
      setSnackbarMessage("Sample marked as 'Don't Include'");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      loadNextClipToReview();
    } catch (error) {
      console.error('Error marking as dont include:', error);
      setSnackbarMessage("Error marking sample as 'Don't Include'");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const saveTranscription = async () => {
    try {
      console.log("Saving transcription for clip:", currentClipId);
      console.log("Transcription data:", { final_transcription: finalTranscription });
      const response = await axios.post(`${API_URL}/api/save-transcription/${currentClipId}`, {
        final_transcription: finalTranscription
      });
      console.log("Save response:", response.data);
    } catch (error) {
      console.error('Error saving transcription:', error.response ? error.response.data : error.message);
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error details:', error.response.data);
      }
    }
  };

  const handleSpeedChange = (speed) => {
    setPlaybackSpeed(speed);
    const audioElement = document.querySelector('audio');
    if (audioElement) {
      audioElement.playbackRate = speed;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <StyledContainer sx={{ 
      ...styles.container, 
      mt: 4
    }}>
      {noClipsMessage ? (
        <Alert severity="info" sx={{ mb: 2 }}>{noClipsMessage}</Alert>
      ) : (
        <Box sx={{ mb: 3 }}>
          {audioSrc ? (
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 2 }}>
                  {currentFilename}
                </Typography>
                {ytLink && (
                  <Link
                    href={formatYouTubeLink(ytLink, timestamps)}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ 
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' },
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<YouTubeIcon />}
                      sx={{ fontSize: '0.75rem', py: 0.5, mr: 1 }}
                    >
                      YouTube
                    </Button>
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      {formatTimestamp(timestamps)}
                    </Typography>
                  </Link>
                )}
              </Box>
              <audio 
                controls 
                src={audioSrc} 
                style={{ width: '100%', marginBottom: '10px' }}
                onError={(e) => {
                  console.error("Audio error:", e);
                  console.error("Audio src:", audioSrc);
                  console.error("Error code:", e.target.error ? e.target.error.code : 'N/A');
                  console.error("Error message:", e.target.error ? e.target.error.message : 'N/A');
                }}
              >
                Your browser does not support the audio element.
                <p>Audio source: {audioSrc}</p>
              </audio>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, mb: 3 }}>
                <Typography variant="body2" sx={{ mr: 1 }}>Speed:</Typography>
                {[0.5, 1, 1.5, 2].map((speed) => (
                  <Button
                    key={speed}
                    variant={playbackSpeed === speed ? "contained" : "outlined"}
                    size="small"
                    onClick={() => handleSpeedChange(speed)}
                    sx={{ mx: 0.5, minWidth: '40px' }}
                  >
                    {speed}x
                  </Button>
                ))}
              </Box>
            </Box>
          ) : (
            <p>No audio file loaded. Audio source: {audioSrc}</p>
          )}
          <Stack direction="row" spacing={3} justifyContent="center" sx={{ mb: 2 }}>  
            <Button 
              variant="outlined" 
              onClick={() => handleLoadAdjacentClip('previous')} 
              sx={{ 
                fontSize: '0.7rem', 
                padding: '2px 8px',
                minWidth: '60px',
                height: '32px',
                margin: '0 12px'
              }}
            >
              Previous Clip
            </Button>
            <Button 
              variant="outlined" 
              onClick={handleReset} 
              sx={{ 
                fontSize: '0.7rem', 
                padding: '2px 8px',
                minWidth: '60px',
                height: '32px',
                margin: '0 12px'
              }}
            >
              Current Clip
            </Button>
            <Button 
              variant="outlined" 
              onClick={() => handleLoadAdjacentClip('next')} 
              sx={{ 
                fontSize: '0.7rem', 
                padding: '2px 8px',
                minWidth: '60px',
                height: '32px',
                margin: '0 12px'
              }}
            >
              Next Clip
            </Button>
          </Stack>
        </Box>
      )}
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Stack flex={1} sx={{ mb: 4 }}>  
          <StyledTextField
            label="Whisper Transcription"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={whisperTranscription}
            disabled
            InputLabelProps={{ shrink: true }}
            sx={styles.textField}
          />
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => handleCopyToFinal(whisperTranscription)}
            sx={styles.button}
          >
            Copy to Final
          </StyledButton>
        </Stack>
        <Stack flex={1} sx={{ mb: 4 }}>  
          <StyledTextField
            label="AI Suggestion"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={aiSuggestion}
            disabled
            InputLabelProps={{ shrink: true }}
            sx={styles.textField}
          />
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => handleCopyToFinal(aiSuggestion)}
            sx={styles.button}
          >
            Copy to Final
          </StyledButton>
        </Stack>
      </Stack>

      <TextField
        label="Human Verified Transcription"
        multiline
        rows={3}
        fullWidth
        value={finalTranscription}
        onChange={(e) => setFinalTranscription(e.target.value)}
        // Always editable
        InputLabelProps={{
          shrink: true,
          sx: { fontSize: '1rem', fontWeight: 'bold' }
        }}
        InputProps={{
          style: { fontSize: '0.9rem' }
        }}
        sx={{ mb: 2, '& .MuiInputBase-root': { padding: '8px' } }}
      />
      
      
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2, 
        mt: 2 
      }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column' : 'row', 
          justifyContent: 'space-between',
          gap: 2
        }}>
          <Button 
            variant="contained" 
            color="error" 
            onClick={handleDontInclude}
            fullWidth={isMobile}
            sx={{ flex: 1 }}
          >
            Don't Include
          </Button>
          <Button 
            variant="contained" 
            onClick={handleSave}
            fullWidth={isMobile}
            sx={{ 
              flex: 1,
              backgroundColor: 'green', 
              '&:hover': { backgroundColor: 'darkgreen' } 
            }}
          >
            Save
          </Button>
          <Button 
            variant="contained" 
            onClick={loadNextClipToReview}
            fullWidth={isMobile}
            sx={{ flex: 1 }}
          >
            Next
          </Button>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column' : 'row', 
          justifyContent: 'space-between',
          gap: 2,
          mt: 2
        }}>
          <Button 
            variant="outlined" 
            onClick={handleReset}
            fullWidth={isMobile}
            sx={{ flex: 1 }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate('/review')}
            fullWidth={isMobile}
            sx={{ flex: 1 }}
          >
            Review 
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
  
    </StyledContainer>
  );
}

export default TranscriptionCorrector;